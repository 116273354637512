<template>
    <settings-wrapper>
        <template v-if="isLoading"><Spinner /></template>
        <template v-slot:child>
            <change-card-modal 
                @on-successful-payment="onSuccessfulPayment"
                @on-cancelled-payment="onCancelledPayment"
            />
            <div class="scrollmenu w-90 center main-bg">
                <!-- <div class="flex justify-between">
                    <div class="griddesign">
                        <div class="">
                            <div class="inner-text-contain">
                                <span class="inner-text  f4 b pr2 mr3">{{ plan?.businessPlan || 'Free' }}</span>
                                <span class="inner-text-color makerounded-md">
                                    <router-link to="/settings/plan" class="inner-text-color">
                                        <span class="">
                                            Upgrade
                                        </span>
                                    </router-link>

                                </span>
                                <span :class="state.activated ? 'ml2-l active-text-color' : 'ml2-l inactive-text-color'">
                                    <router-link to="" class="" @click="verifyPayment()">
                                        <span :class="state.activated ? 'ml2-l active-text-color' : 'ml2-l inactive-text-color'">
                                            <strong>{{ state.activated ? 'Activated' : 'Activate' }}</strong>
                                        </span>
                                    </router-link>

                                </span>
                            </div>
                            <div class="inner-text-contain1 mt2">
                                <span class=" pr2 ">Active Users</span> <span class="  f4 b">{{ plan?.users || 1 }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="griddesign">
                        <div class="">
                            <p class="inner-text  f4 b pr2">Billing Details</p>
                            <div class="inter-p">

                                <div class="flex flex-column mt0-l pt1">
                                    <div class="flex flex-row">
                                        <div class="">
                                            <strong>Card Number:</strong>
                                        </div>
                                        <div class="ml2">
                                            <em class="">****{{ state.lastFourDigits || '0000' }}</em>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-column pt1">
                                    <div class="flex flex-row">
                                        <div class="">
                                            <strong>Next Payment Date:</strong>
                                        </div>
                                        <div class="ml2">
                                            <em class="">{{ state.nextPaymentDate || '' }}</em>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-column pt1">
                                    <div class="">
                                        <div class="">
                                            <strong>Billing Address: </strong>
                                        </div>
                                        <div class="">
                                            <input
                                                type="text"
                                                v-model="state.billingAddress"
                                                placeholder="Enter Billing Address"
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            />
                                            <button class="pagebutton ml1" @click="updateBilling">submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="griddesign">
                        <p class="inner-text  f4 b pr2">Payment Card</p>
                        <div class="payment mt2">
                            Add Card
                        </div>
                    </div>
                </div> -->
                <div class="">
                    <div class="flex justify-between">
                        <div class="griddesign">
                            <div class="p1">
                                <div class="inner-text-contain">
                                    <span class="inner-text  f4 b pr2 mr3">{{ plan?.businessPlan || 'Free' }}</span>
                                    <span class="inner-text-color makerounded-md">
                                        <router-link to="/settings/plan" class="inner-text-color">
                                            <span class="">
                                                Upgrade
                                            </span>
                                        </router-link>

                                        <!-- Upgrade -->
                                    </span>
                                    <span :class="state.activated ? 'ml2-l active-text-color' : 'ml2-l inactive-text-color'">
                                        <router-link to="" class="" @click="verifyPayment()">
                                            <span
                                                :class="state.activated ? 'ml2-l active-text-color' : 'ml2-l inactive-text-color'"
                                            >
                                                <strong>{{ state.activated ? 'Activated' : 'Activate' }}</strong>
                                            </span>
                                        </router-link>

                                        <!-- Upgrade -->
                                    </span>
                                </div>
                                <div class="inner-text-contain1 mt2">
                                    <span class=" pr2 ">Active Users</span>
                                    <span class="  f4 b">{{ members.length === 1 ? 0 : members.length - 1 }} of {{ plan?.users || 1 }}</span>
                                    <span v-if="members?.length < plan?.users" class="ml2">
                                        <router-link :to="{ name: 'Teams' }">
                                            <font-awesome-icon 
                                                icon="fa fa-plus-circle" 
                                                style="font-size:1.5em; color:#132c8c" 
                                                v-tippy="'Add user'" 
                                            />
                                        </router-link>
                                    </span>
                                </div>
                                <div class="inner-text-contain1 mt2">
                                    <span class="pr2">Active Businesses</span>
                                    <span class="  f4 b">{{ businesses?.length > 0 ? businesses.length : 1 }} of {{ plan?.businesses >= 10000 ? 'Unlimited' : (plan?.businesses || 1) }}</span>
                                    <span v-if="businesses?.length < plan?.businesses" class="ml2">
                                        <router-link :to="{ name: 'AddBusiness' }">
                                            <font-awesome-icon 
                                                icon="fa fa-plus-circle" 
                                                style="font-size:1.5em; color:#132c8c" 
                                                v-tippy="'Add new business/branch'" 
                                            />
                                        </router-link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="griddesign">
                            <div class="">
                                <p class="inner-text  f4 b pr2">Billing Details</p>
                                <div class="inter-p">
                                    <!-- <P>
                                You need to add a payment card to be able to add a billing address
                            </P> -->
                                    <!-- list billing info -->
                                    <div class="flex flex-column mt0-l pt1">
                                        <div class="flex flex-row">
                                            <div class="">
                                                <strong>Card Number:</strong>
                                            </div>
                                            <div class="ml2">
                                                <em class="">****{{ state.lastFourDigits || '0000' }}</em>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-column pt1">
                                        <div class="flex flex-row">
                                            <div class="">
                                                <strong>Next Payment Date:</strong>
                                            </div>
                                            <div class="ml2">
                                                <em class="">{{ state.nextPaymentDate || '' }}</em>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-column pt1">
                                        <div class="">
                                            <div class="">
                                                <strong>Billing Address: </strong>
                                            </div>
                                            <div class="">
                                                <!-- <label>Billing Address: </label> -->
                                                <input
                                                    type="text"
                                                    v-model="state.billingAddress"
                                                    placeholder="Enter Billing Address"
                                                    style="padding-top: 2px; padding-bottom: 2px;"
                                                />
                                                <button class="pagebutton ml1" @click="updateBilling">submit</button>

                                                <!-- <a href="#" @click="updateBilling" class="pl2">
                                                <img src="https://img.icons8.com/ios-filled/22/000000/checked--v1.png" />
                                            </a> -->
                                            </div>
                                            <div class="mt2">
                                                <button class="btn" @click="showChangeCardModal(true)">Change Card</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="griddesign">
                            <p class="inner-text  f4 b pr2">Payment Card</p>
                            <div class="payment mt2">
                                Add Card
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="bg-container-2  w-90 center ">
                <h4 class="Upcoming-Invoice">
                    Upcoming Invoice
                </h4>

                <div class="Upcoming-Invoice-table ">
                    <div class="overflow-auto">
                        <table class="f6 w-100" cellspacing="0">
                            <thead>
                                <tr>
                                    <th class="fw6 bb b--black-20 tl p9 pr3 bg-white">Description</th>
                                    <th class="fw6 bb b--black-20 tl p9 pl10 bg-white">Qty</th>
                                    <th class="fw6 bb b--black-20 tl p9 pr3 bg-white">Unit Price</th>
                                    <th class="fw6 bb b--black-20 tl p9 pr3 bg-white">Total</th>
                                </tr>
                            </thead>
                            <tbody class="lh-copy">
                                <tr>
                                    <td class="pv2  pl3 bb b--black-20">Plan Name: {{ plan?.businessPlan }}</td>
                                    <td class="pv2  pl3 bb b--black-20">1</td>
                                    <td class="pv2  pl3 bb b--black-20">
                                        {{ plan?.isFreeTrial ? 'Free trial' : formatAmount(plan?.planCost, $store.state.Settings?.currency) }}
                                    </td>
                                    <td class="pv2  pl3 bb b--black-20">
                                        {{ plan?.isFreeTrial ? 'Free trial' : formatAmount(plan?.planCost, $store.state.Settings?.currency) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pv2  pl3 bb b--black-20">Additional users</td>
                                    <td class="pv2  pl3 bb b--black-20">{{ plan?.additionalUsers }}</td>
                                    <td class="pv2  pl3 bb b--black-20">
                                        {{ plan?.isFreeTrial ? 'Free trial' : formatAmount(plan?.userCost, $store.state.Settings?.currency) }}
                                    </td>
                                    <td class="pv2  pl3 bb b--black-20">
                                        {{
                                            plan?.isFreeTrial ? 'Free trial' : formatAmount(
                                                plan?.additionalUsers * plan?.userCost,
                                                $store.state.Settings?.currency
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pv2  pl3 bb b--black-20">Additional business</td>
                                    <td class="pv2  pl3 bb b--black-20">{{ plan?.additionalBusiness }}</td>
                                    <td class="pv2  pl3 bb b--black-20">{{ plan?.isFreeTrial ? 'Free trial' : formatAmount(plan?.businessCost, $store.state.Settings?.currency) }}</td>
                                    <td class="pv2  pl3 bb b--black-20">{{ plan?.isFreeTrial ? 'Free trial' : formatAmount(plan?.additionalBusiness * plan?.businessCost, $store.state.Settings?.currency) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="buttom-billings">
                    <h4 class="Upcoming-Invoice-1">
                        Billing history
                    </h4>
                    <!-- <h4 class="Upcoming-Invoice-1">
                        Upcoming Invoice
                    </h4> -->
                    <div class="Upcoming-Invoice-table ">
                        <div class="overflow-auto">
                            <table class="f6 w-100" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th class="fw6 bb b--black-20 tl p9 pr3 bg-white">Invoice No</th>
                                        <th class="fw6 bb b--black-20 tl p9 pr3 bg-white">Description</th>
                                        <th class="fw6 bb b--black-20 tl p9 pr3 bg-white">Amount</th>
                                        <th class="fw6 bb b--black-20 tl p9 pr3 bg-white">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="lh-copy">
                                    <tr v-for="history of paymentHistory.data" v-bind:key="history">
                                        <td class="pv2 pl2 bb b--black-20">{{ history.payment_reference }}</td>
                                        <td class="pv2 pl3 bb b--black-20">{{ history.description }}</td>
                                        <td class="pv2 pl3 bb b--black-20">{{ formatAmount(history.amount, $store.state.Settings?.currency) }}</td>
                                        <td class="pv2 pl3 bb b--black-20">
                                            <router-link
                                                :to="{ name: 'PaymentInvoice', params: { id: history.payment_reference } }"
                                            >
                                                <!-- <button class="btn btn-primary">View</button> -->
                                                <img src="../../assets/images/enable.svg" class="pr2" alt="" />
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </settings-wrapper>
</template>

<script>
import { computed, onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'
import OrgData from '@/components/OrgData'
import ChangeCardModal from './widgets/ChangeCardModal'
import { formatAmount } from '@/utils/lib'

export default {
    name: 'Billings',
    components: { SettingsWrapper, OrgData, ChangeCardModal },

    setup() {
        const store = useStore()
        // const orgData = computed(() => store?.state?.Auth?.userData)
        const organization = computed(() => store.state?.Settings?.organization)
        const plan = computed(() => store.state?.Settings?.paymentPlan)
        const members = computed(() => store.state.Settings.members)
        const businesses = computed(() => store?.state?.Settings?.businesses)
        const customerCount = computed(() => store?.state.Entity.customerCount)
        const paymentHistory = computed(() => store?.state?.Settings?.paymentHistory)
        const cardDetails = computed(() => store?.state?.Settings?.cardDetails)
        const isLoading = computed(() => store.state.Settings.isLoading)

        onMounted(() => {
            store.dispatch('Settings/getOrganization')
            store.dispatch('Settings/getPlan')
            store.dispatch('Settings/getTeamMembers')
            store.dispatch('Settings/getBusinesses')
            store.dispatch('Settings/getCardDetails')
            store.dispatch('Settings/getPaymentHistory')
            store.dispatch('Entity/countCustomer')
        })

        const initaialState = {
            activated: false,
            nextPaymentDate: '',
            lastFourDigits: '',
            billingAddress: ''
        }

        const state = reactive(initaialState)
        // watch plan
        watch(plan, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                state.activated = newValue?.status === 'success'
                const expiryDate = new Date(newValue?.expiryDate)
                state.nextPaymentDate = new Date(new Date(expiryDate).setDate(new Date(expiryDate).getDate() + 1)).toDateString()
                state.billingAddress = newValue?.billingAddress
                if (state.nextPaymentDate === 'Invalid Date') {
                    state.nextPaymentDate = 'N/A'
                }
            }
        })

        // watch customerCount
        watch(customerCount, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                // store.dispatch('Entity/countCustomer')
            }
        })
        // watch paymentHistory
        watch(paymentHistory, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                // store.dispatch('Entity/countCustomer')
            }
        })

        // watch cardDetails
        watch(cardDetails, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                state.lastFourDigits = newValue[0]?.data?.authorization?.last4
                // store.dispatch('Entity/countCustomer')
            }
        })

        const verifyPayment = async reference => {
            if (!reference && plan.value) {
                if (state.activated) {
                    store.dispatch('Alert/setAlert', {
                        status: true,
                        message: 'Payment already activated'
                    })
                    return
                }
                const activatePayload = {
                    reference: plan.value.payment_reference,
                    type: 'activate'
                }
                await store.dispatch('Settings/verifyPayment', activatePayload)
                return
            } else if (!reference && !plan.value) {
                return
            } else {
                const verifyPayload = {
                    reference,
                    type: 'verify'
                }
                await store.dispatch('Settings/verifyPayment', verifyPayload)
            }
        }

        const updateBilling = async () => {
            const updatePayload = {
                reference: plan.value.payment_reference,
                billingAddress: state.billingAddress,
                type: 'update'
            }
            await store.dispatch('Settings/verifyPayment', updatePayload)
        }

        const showChangeCardModal = (payload = true) => {
            store.dispatch('Settings/showChangeCardModal', payload)
        }

        const onSuccessfulPayment = async (response) => {
            if (response.status === "success") {
                response.planId = plan.value._id
                store.dispatch('Settings/changeCard', response).then((data) => {
                    if (data.status) {
                        showChangeCardModal(false)
                    }
                })
            }
        }

        const onCancelledPayment = () => {
            showChangeCardModal(false)
        }

        return {
            organization,
            plan,
            members,
            businesses,
            customerCount,
            paymentHistory,
            cardDetails,
            formatAmount,
            verifyPayment,
            state,
            isLoading,
            updateBilling,
            showChangeCardModal,
            onSuccessfulPayment,
            onCancelledPayment,
        }
    }
}
</script>

<style scoped>
@media screen and (max-width: 1020px) {
    .box-container {
        display: flex;
        padding: 1rem 0px;
        margin: 10px;
        flex-direction: column;
        white-space: normal !important;
    }
}

.buttom-billings {
    margin-top: 10px;
}
.lh-copy {
    background-color: #fff;
    padding: 0px 20px;
}
.p9 {
    padding: 10px;
}
.bg-white {
    background-color: #e8ecf5;
}
.bb {
    border-bottom: 1px solid rgb(233, 230, 230);
    border-bottom-width: 1px;
}
.Upcoming-Invoice-table {
    padding: 0px 10px;
}
.bg-container-2 {
    background: rgb(251, 252, 254);
}

.Upcoming-Invoice-1 {
    background-color: #fff;
    margin: 0px 10px;
    padding: 5px 10px;
}
.Upcoming-Invoice {
    background-color: #fff;
    margin: 10px;
    border: 1px solid #e3e8ee;
    padding: 5px 10px;
}

.billing-box-1 {
    width: 55%;
    height: 150px;
    background: rgb(251, 252, 254);
    margin-right: 20px;
}
.billing-box-2 {
    width: 100%;
    height: 100px;
    background-color: white;
    border: 1px solid #e3e8ee;
    padding: 10px;
}

.box-container {
    display: flex;
    padding: 1rem 0px;
    margin: 10px;
}
.main-bg {
    background: rgb(251, 252, 254);
}

.inner-box1 {
    width: 100%;
    height: 80px;
    background-color: white;
    border: 1px solid #e3e8ee;
}
.inner-box2 {
    width: 100%;
    height: 50px;
    background-color: white;
    bottom: 1px;
    margin-top: 1rem;
    border: 1px solid #e3e8ee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
}

.inner-text-contain {
    padding: 10px !important;
}
.inner-text-contain1 {
    padding: 0px 10px;
    padding-bottom: 10px;
}
.inner-text-color {
    background-color: rgb(19, 44, 140);
    color: #fff;
    font-weight: 500;
    padding: 3px 5px;
}
.inactive-text-color {
    background-color: red;
    color: #fff;
    padding: 3px 5px;
}
.active-text-color {
    background-color: #2ef23b;
    color: black;
    padding: 3px 5px;
}

.makerounded-md {
    border-radius: 0.375rem /* 6px */;
}

.griddesign {
    border: 1px solid #e3e8ee;
    width: 100%;
    padding: 10px;
}

.pagebutton {
    padding: 4px 6px;
    font-weight: 500;
}

.payment {
    background: #aab9f2;
    font-weight: 700;
    color: #132c8c;
    width: 10rem;
    border-radius: 0.375rem /* 6px */;
    text-align: center;
    padding: 10px;
}
</style>
